import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const API_URL_DOC_EN =
  'https://veta.ingka.com/serviceconnector/swagger/../services/rest/documents';

const API_URL_DOC_EN_INT =
  'https://veta-int.kcenter.usu.com/serviceconnector/swagger/../services/rest/documents';

const API_LOCALE_EN_ES =
  '&cb=1_88&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_HU =
  '&cb=1_899&lang=hu&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_PT =
  '&cb=1_246&lang=pt&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_PT =
  '&cb=1_246&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_CZ =
  '&cb=1_951&lang=cs&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_SK =
  '&cb=1_847&lang=sk&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_NO =
  '&cb=1_1003&lang=no&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_NO =
  '&cb=1_1003&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_GB =
  '&cb=1_390&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_AU =
  '&cb=1_2127&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_IE =
  '&cb=1_766&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_IT =
  '&cb=1_342&lang=it&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

// Note Lang should be sv but due to USU bug it's se

const API_LOCALE_SE =
  '&cb=1_628&lang=sv&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

  const API_LOCALE_EN_SE =
  '&cb=1_628&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_AT =
  '&cb=1_1559&lang=de&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_AT =
  '&cb=1_1559&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_BE =
  '&cb=1_571&lang=nl&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_FR_BE =
  '&cb=1_571&lang=fr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_BE =
  '&cb=1_571&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_DK =
  '&cb=1_294&lang=da&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_PL =
  '&cb=1_517&lang=pl&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_DE =
  '&cb=1_1423&lang=de&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_DE =
  '&cb=1_1423&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_FI =
  '&cb=1_1055&lang=fi&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_FI =
  '&cb=1_1055&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_FR =
  '&cb=1_1107&lang=fr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_FR_CA =
  '&cb=1_147&lang=fr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_CA =
  '&cb=1_147&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_RS =
  '&cb=1_1180&lang=sr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_SI =
  '&cb=1_1475&lang=sl&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_UA =
  '&cb=1_1333&lang=uk&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_HR =
  '&cb=1_1282&lang=hr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_RO =
  '&cb=1_1231&lang=ro&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_DE_CH =
  '&cb=1_716&lang=de&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_FR_CH =
  '&cb=1_716&lang=fr&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_US =
  '&cb=1_1654&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_ES_US =
  '&cb=1_1654&lang=es&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_CH =
  '&cb=1_716&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_IT_CH =
  '&cb=1_716&lang=it&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_URL_SEARCH =
  'https://veta.ingka.com/serviceconnector/swagger/../services/rest/content/search';

const API_URL_SEARCH_INT =
  'https://veta-int.kcenter.usu.com/serviceconnector/swagger/../services/rest/content/search';

const API_URL_CATS_EN =
  'https://veta.ingka.com/serviceconnector/swagger/../services/rest/content/categories?';

const API_URL_DOC =
  'https://veta.ingka.com/serviceconnector/swagger/../services/rest/documents';

const API_LOCALE_ES =
  '&cb=1_88&lang=es&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EU_ES =
  '&cb=1_88&lang=eu&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_GL_ES =
  '&cb=1_88&lang=gl&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_CA_ES =
  '&cb=1_88&lang=ca&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_NL =
  '&cb=1_438&lang=nl&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_NL =
  '&cb=1_438&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_RU =
  '&cb=1_1867&lang=ru&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_KR =
  '&cb=1_2016&lang=ko&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_KR =
  '&cb=1_2016&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_JP =
  '&cb=1_1810&lang=ja&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_JP =
  '&cb=1_1810&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

const API_LOCALE_EN_IN =
  '&cb=1_1941&lang=en&docType=2g3f206g-b01f-4ggc-8dd5-d22e55gfe1f8';

i18n.use(initReactI18next).init({
  fallbackLng: 'en-ES',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: [
      'htmlTag',
      'navigator',
      'querystring',
      'cookie',
      'localStorage',
      'sessionStorage',
      'path',
      'subdomain',
    ],
  },
  resources: {
    'en-ES': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-cats': API_URL_CATS_EN,
        'url-doc': API_URL_DOC_EN,
        locale: API_LOCALE_EN_ES,
      }
    },
    'it-IT': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_IT,
      }
    },
    'en-GB': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_GB,
      }
    },
    'en-IE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_IE,
      }
    },
    'cs-CZ': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_CZ,
      }
    },
    'sk-SK': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_SK,
      }
    },
    'es-ES': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_ES,
      }
    },
    'eu-ES': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-cats': API_URL_CATS_EN,
        'url-doc': API_URL_DOC_EN,
        locale: API_LOCALE_EU_ES,
      }
    },
    'ca-ES': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-cats': API_URL_CATS_EN,
        'url-doc': API_URL_DOC_EN,
        locale: API_LOCALE_CA_ES,
      }
    },
    'gl-ES': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-cats': API_URL_CATS_EN,
        'url-doc': API_URL_DOC_EN,
        locale: API_LOCALE_GL_ES,
      }
    },
    'nl-NL': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_NL,
      }
    },
    'en-NL': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_NL,
      }
    },
    'fr-FR': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_FR,
      }
    },
    'fr-CH': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_FR_CH,
      }
    },
    'en-CH': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_CH,
      }
    },
    'it-CH': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_IT_CH,
      }
    },
    'de-CH': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_DE_CH,
      }
    },
    'sr-RS': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_RS,
      }
    },
    'hr-HR': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_HR,
      }
    },
    'sl-SI': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_SI,
      }
    },
    'ro-RO': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_RO,
      }
    },
    'uk-UA': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_UA,
      }
    },
    'de-DE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_DE,
      }
    },
    'en-DE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_DE,
      }
    },
    'en-CA': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_CA,
      }
    },
    'fr-CA': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_FR_CA,
      }
    },
    'fi-FI': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_FI,
    }
  },
    'en-FI': {
     translation: {
       'url-search': API_URL_SEARCH,
       'url-doc': API_URL_DOC,
       locale: API_LOCALE_EN_FI,
    }
  },
    'da-DK': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_DK,
      }
    },
    'pl-PL': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_PL,
      }
    },
    'de-AT': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_AT,
      }
    },
    'en-AT': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-cats': API_URL_CATS_EN,
        'url-doc': API_URL_DOC_EN,
        locale: API_LOCALE_EN_AT,
      }
    },
    'fr-BE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_FR_BE,
      }
    },
    'nl-BE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_BE,
      }
    },
    'en-BE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_BE,
      }
    },
    'no-NO': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_NO,
      }
    },
    'en-NO': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_NO,
      }
    },
    'sv-SE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_SE,
      }
    },
    'en-SE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_SE,
      }
    },
    'hu-HU': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_HU,
      }
    },
    'pt-PT': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_PT,
      }
    },
    'en-PT': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_PT,
      }
    },
    'en-US': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_US,
      }
    },
    'es-US': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_ES_US,
      }
    },
    'ru-RU': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_RU,
      }
    },
    'en-R1': {
      translation: {
        'url-search': API_URL_SEARCH_INT,
        'url-doc': API_URL_DOC_EN_INT,
        locale: API_LOCALE_EN_NL,
      }
    },
    'en-KR': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_KR,
      }
    },
    'ko-KR': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_KR,
      }
    },
    'ja-JP': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_JP,
      }
    },
    'en-JP': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_JP,
      }
    },
    'en-IN': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_IN,
      }
    },
    'en-AU': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_AU,
      }
    },
    'rv-RE': {
      translation: {
        'url-search': API_URL_SEARCH,
        'url-doc': API_URL_DOC,
        locale: API_LOCALE_EN_AU,
        
        searching: 'Searching...',
        search: 'Find answers to your question here',
        loading: 'Loading',
        'no-results':
          'We did not find any matches for results for search term: {{query}}',
        'try-again': 'Please try again later',
        results: 'We found {{number}} results for: {{query}}',
        error: 'Something went wrong',
        'spell-check': 'Try checking the spelling or using different keywords.',
        close: 'Close',
        showMore: 'Show more',
        showLess: 'Show less',
        retrieving: 'Retrieving',
        'feedback-first': 'Did you find this useful?',
        'feedback-second': 'Thank you for your feedback!',
        'not-available':
          '	Service is currently not available, please try again later.',
      },
    },
  },
});

export default i18n;
